@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?k4xa2a');
  src:  url('fonts/icomoon.eot?k4xa2a#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?k4xa2a') format('truetype'),
    url('fonts/icomoon.woff?k4xa2a') format('woff'),
    url('fonts/icomoon.svg?k4xa2a#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cog:before {
  content: "\ea44";
}
.icon-help-and-feedback:before {
  content: "\ea45";
}
.icon-home1:before {
  content: "\ea46";
}
.icon-magic-bar:before {
  content: "\ea47";
}
.icon-paper:before {
  content: "\ea48";
}
.icon-users:before {
  content: "\ea49";
}
.icon-case:before {
  content: "\ea50";
}
.icon-check-mark:before {
  content: "\ea42";
}
.icon-navigate:before {
  content: "\ea43";
}
.icon-ai-star:before {
  content: "\e900";
  color: #0065cb;
}
.icon-arrow-down:before {
  content: "\e901";
  color: #0054ad;
}
.icon-arrow-left:before {
  content: "\e902";
  color: #6b7280;
}
.icon-arrow-right-outline:before {
  content: "\e903";
  color: #0065cb;
}
.icon-Avatar:before {
  content: "\e904";
}
.icon-back-arrow:before {
  content: "\e905";
  color: #0a3a70;
}
.icon-blog-arrow:before {
  content: "\e906";
  color: #ff5a1f;
}
.icon-check-circle .path1:before {
  content: "\e907";
  color: rgb(255, 138, 76);
}
.icon-check-circle .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-clipboard-check .path1:before {
  content: "\e909";
  color: rgb(255, 138, 76);
}
.icon-clipboard-check .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-clipboard-check2:before {
  content: "\e90b";
}
.icon-close-white:before {
  content: "\e90c";
}
.icon-code-merge:before {
  content: "\e90d";
}
.icon-confetti .path1:before {
  content: "\e90e";
  color: rgb(255, 90, 31);
  opacity: 0.94;
}
.icon-confetti .path2:before {
  content: "\e90f";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.93;
}
.icon-confetti .path3:before {
  content: "\e910";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.93;
}
.icon-confetti .path4:before {
  content: "\e911";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
}
.icon-confetti .path5:before {
  content: "\e912";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.88;
}
.icon-confetti .path6:before {
  content: "\e913";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.86;
}
.icon-confetti .path7:before {
  content: "\e914";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.98;
}
.icon-confetti .path8:before {
  content: "\e915";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.89;
}
.icon-confetti .path9:before {
  content: "\e916";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.93;
}
.icon-credit-card:before {
  content: "\e917";
}
.icon-demo:before {
  content: "\e918";
}
.icon-dots:before {
  content: "\e919";
}
.icon-envelope:before {
  content: "\e91a";
  color: #6b7280;
}
.icon-face-grin-stars:before {
  content: "\e91b";
}
.icon-faq-arrow-down:before {
  content: "\e91c";
  color: #6b7280;
}
.icon-faq-arrow-up:before {
  content: "\e91d";
}
.icon-feature1:before {
  content: "\e91e";
  color: #ff5a1f;
}
.icon-feature2:before {
  content: "\e91f";
  color: #ff5a1f;
}
.icon-feature3:before {
  content: "\e920";
  color: #ff5a1f;
}
.icon-file-chart-bar:before {
  content: "\e921";
}
.icon-file-shield:before {
  content: "\e922";
}
.icon-fingerprint .path1:before {
  content: "\e923";
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path2:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path3:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path4:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path5:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path6:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path7:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path8:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path9:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path10:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path11:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path12:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path13:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path14:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path15:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path16:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint .path17:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint .path18:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fire:before {
  content: "\e935";
}
.icon-google .path1:before {
  content: "\e936";
  color: rgb(66, 133, 244);
}
.icon-google .path2:before {
  content: "\e937";
  margin-left: -0.9521484375em;
  color: rgb(52, 168, 83);
}
.icon-google .path3:before {
  content: "\e938";
  margin-left: -0.9521484375em;
  color: rgb(251, 188, 4);
}
.icon-google .path4:before {
  content: "\e939";
  margin-left: -0.9521484375em;
  color: rgb(234, 67, 53);
}
.icon-hero2:before {
  content: "\e93a";
}
.icon-hero4:before {
  content: "\e93b";
}
.icon-info:before {
  content: "\e93c";
  color: #9ca3af;
}
.icon-input-go .path1:before {
  content: "\e93d";
  color: rgb(223, 237, 255);
}
.icon-input-go .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(10, 131, 237);
}
.icon-light-bulb:before {
  content: "\e93f";
}
.icon-linkedin-1:before {
  content: "\e940";
  color: #9ca3af;
}
.icon-LinkedIn:before {
  content: "\e941";
  color: #0a66c2;
}
.icon-Logo .path1:before {
  content: "\e942";
  color: rgb(255, 255, 255);
}
.icon-Logo .path2:before {
  content: "\e943";
  margin-left: -4.5em;
  color: rgb(255, 89, 0);
}
.icon-Logo .path3:before {
  content: "\e944";
  margin-left: -4.5em;
  color: rgb(252, 143, 0);
}
.icon-Logo .path4:before {
  content: "\e945";
  margin-left: -4.5em;
  color: rgb(0, 61, 128);
}
.icon-Logo .path5:before {
  content: "\e946";
  margin-left: -4.5em;
  color: rgb(44, 102, 170);
}
.icon-Logo .path6:before {
  content: "\e947";
  margin-left: -4.5em;
  color: rgb(255, 209, 0);
}
.icon-Logo .path7:before {
  content: "\e948";
  margin-left: -4.5em;
  color: rgb(252, 143, 0);
}
.icon-Logo .path8:before {
  content: "\e949";
  margin-left: -4.5em;
  color: rgb(0, 61, 128);
}
.icon-map-pin:before {
  content: "\e94a";
  color: #6b7280;
}
.icon-message-caption:before {
  content: "\e94b";
}
.icon-Microsoft-logo .path1:before {
  content: "\e94c";
  color: rgb(243, 243, 243);
}
.icon-Microsoft-logo .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(243, 83, 37);
}
.icon-Microsoft-logo .path3:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(129, 188, 6);
}
.icon-Microsoft-logo .path4:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(5, 166, 240);
}
.icon-Microsoft-logo .path5:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 186, 8);
}
.icon-nav-arrow:before {
  content: "\e951";
  color: #d1d5db;
}
.icon-plus:before {
  content: "\e952";
}
.icon-question-mark:before {
  content: "\e953";
  color: #6b7280;
}
.icon-regenerate-text:before {
  content: "\e954";
  color: #0a83ed;
}
.icon-rewrite:before {
  content: "\e955";
  color: #0a83ed;
}
.icon-Signup:before {
  content: "\e956";
}
.icon-upload:before {
  content: "\e957";
  color: #6b7280;
}
.icon-Vector:before {
  content: "\e958";
}
.icon-x-circle:before {
  content: "\e959";
  color: #6b7280;
}
.icon-Ai-vector:before {
  content: "\e967";
  color: #0065cb;
}
.icon-arrow-down1:before {
  content: "\e968";
  color: #0054ad;
}
.icon-arrow-left1:before {
  content: "\e969";
  color: #6b7280;
}
.icon-arrow-right-outline1:before {
  content: "\e96a";
  color: #0065cb;
}
.icon-Avatar1:before {
  content: "\e96b";
}
.icon-back-arrow1:before {
  content: "\e96c";
  color: #0a3a70;
}
.icon-bars:before {
  content: "\e96d";
}
.icon-bell:before {
  content: "\e96e";
}
.icon-blog-arrow1:before {
  content: "\e96f";
  color: #ff5a1f;
}
.icon-blue-x:before {
  content: "\e970";
}
.icon-calendar:before {
  content: "\e971";
}
.icon-check-circle1 .path1:before {
  content: "\e972";
  color: rgb(255, 138, 76);
}
.icon-check-circle1 .path2:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-chevron:before {
  content: "\e974";
}
.icon-clipboard-check1 .path1:before {
  content: "\e975";
  color: rgb(255, 138, 76);
}
.icon-clipboard-check1 .path2:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-clipboard-check21:before {
  content: "\e977";
}
.icon-close-white1:before {
  content: "\e978";
}
.icon-code-merge1:before {
  content: "\e979";
}
.icon-confetti1 .path1:before {
  content: "\e97a";
  color: rgb(255, 90, 31);
  opacity: 0.94;
}
.icon-confetti1 .path2:before {
  content: "\e97b";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.93;
}
.icon-confetti1 .path3:before {
  content: "\e97c";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.93;
}
.icon-confetti1 .path4:before {
  content: "\e97d";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
}
.icon-confetti1 .path5:before {
  content: "\e97e";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.88;
}
.icon-confetti1 .path6:before {
  content: "\e97f";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.86;
}
.icon-confetti1 .path7:before {
  content: "\e980";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.98;
}
.icon-confetti1 .path8:before {
  content: "\e981";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.89;
}
.icon-confetti1 .path9:before {
  content: "\e982";
  margin-left: -1.478515625em;
  color: rgb(255, 90, 31);
  opacity: 0.93;
}
.icon-create-job:before {
  content: "\e983";
}
.icon-credit-card1:before {
  content: "\e984";
}
.icon-demo1:before {
  content: "\e985";
}
.icon-dots1:before {
  content: "\e986";
}
.icon-envelope1:before {
  content: "\e987";
  color: #6b7280;
}
.icon-face-grin-stars1:before {
  content: "\e988";
}
.icon-faq-arrow-down1:before {
  content: "\e989";
  color: #6b7280;
}
.icon-faq-arrow-up1:before {
  content: "\e98a";
}
.icon-feature11:before {
  content: "\e98b";
  color: #ff5a1f;
}
.icon-feature21:before {
  content: "\e98c";
  color: #ff5a1f;
}
.icon-feature31:before {
  content: "\e98d";
  color: #ff5a1f;
}
.icon-file-chart-bar1:before {
  content: "\e98e";
}
.icon-file-shield1:before {
  content: "\e98f";
}
.icon-fingerprint1 .path1:before {
  content: "\e990";
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path2:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path3:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path4:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path5:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path6:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path7:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path8:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path9:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path10:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path11:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path12:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path13:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path14:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path15:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path16:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fingerprint1 .path17:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(255, 138, 76);
}
.icon-fingerprint1 .path18:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-fire1:before {
  content: "\e9a2";
}
.icon-folder:before {
  content: "\e9a3";
}
.icon-footer-hero .path1:before {
  content: "\e9a4";
  color: rgb(255, 90, 31);
}
.icon-footer-hero .path2:before {
  content: "\e9a5";
  margin-left: -1.9365234375em;
  color: rgb(255, 138, 76);
}
.icon-footer-hero .path3:before {
  content: "\e9a6";
  margin-left: -1.9365234375em;
  color: rgb(5, 69, 135);
}
.icon-footer-hero .path4:before {
  content: "\e9a7";
  margin-left: -1.9365234375em;
  color: rgb(10, 131, 237);
}
.icon-footer-hero .path5:before {
  content: "\e9a8";
  margin-left: -1.9365234375em;
  color: rgb(250, 202, 21);
}
.icon-footer-hero .path6:before {
  content: "\e9a9";
  margin-left: -1.9365234375em;
  color: rgb(255, 138, 76);
}
.icon-full-logo-white .path1:before {
  content: "\e9aa";
  color: rgb(255, 90, 31);
}
.icon-full-logo-white .path2:before {
  content: "\e9ab";
  margin-left: -5.25em;
  color: rgb(255, 138, 76);
}
.icon-full-logo-white .path3:before {
  content: "\e9ac";
  margin-left: -5.25em;
  color: rgb(0, 84, 173);
}
.icon-full-logo-white .path4:before {
  content: "\e9ad";
  margin-left: -5.25em;
  color: rgb(0, 101, 203);
}
.icon-full-logo-white .path5:before {
  content: "\e9ae";
  margin-left: -5.25em;
  color: rgb(255, 255, 255);
  opacity: 0.2;
}
.icon-full-logo-white .path6:before {
  content: "\e9af";
  margin-left: -5.25em;
  color: rgb(250, 202, 21);
}
.icon-full-logo-white .path7:before {
  content: "\e9b0";
  margin-left: -5.25em;
  color: rgb(255, 138, 76);
}
.icon-full-logo-white .path8:before {
  content: "\e9b1";
  margin-left: -5.25em;
  color: rgb(255, 255, 255);
}
.icon-full-logo-white .path9:before {
  content: "\e9b2";
  margin-left: -5.25em;
  color: rgb(255, 255, 255);
}
.icon-full-logo-white .path10:before {
  content: "\e9b3";
  margin-left: -5.25em;
  color: rgb(255, 255, 255);
}
.icon-full-logo-white .path11:before {
  content: "\e9b4";
  margin-left: -5.25em;
  color: rgb(255, 255, 255);
}
.icon-full-logo-white .path12:before {
  content: "\e9b5";
  margin-left: -5.25em;
  color: rgb(255, 255, 255);
}
.icon-full-logo-white .path13:before {
  content: "\e9b6";
  margin-left: -5.25em;
  color: rgb(255, 255, 255);
}
.icon-full-Logo .path1:before {
  content: "\e9b7";
  color: rgb(255, 90, 31);
}
.icon-full-Logo .path2:before {
  content: "\e9b8";
  margin-left: -5.423828125em;
  color: rgb(255, 138, 76);
}
.icon-full-Logo .path3:before {
  content: "\e9b9";
  margin-left: -5.423828125em;
  color: rgb(10, 58, 112);
}
.icon-full-Logo .path4:before {
  content: "\e9ba";
  margin-left: -5.423828125em;
  color: rgb(0, 101, 203);
}
.icon-full-Logo .path5:before {
  content: "\e9bb";
  margin-left: -5.423828125em;
  color: rgb(250, 202, 21);
}
.icon-full-Logo .path6:before {
  content: "\e9bc";
  margin-left: -5.423828125em;
  color: rgb(255, 138, 76);
}
.icon-full-Logo .path7:before {
  content: "\e9bd";
  margin-left: -5.423828125em;
  color: rgb(10, 58, 112);
}
.icon-full-Logo .path8:before {
  content: "\e9be";
  margin-left: -5.423828125em;
  color: rgb(10, 58, 112);
}
.icon-full-Logo .path9:before {
  content: "\e9bf";
  margin-left: -5.423828125em;
  color: rgb(10, 58, 112);
}
.icon-full-Logo .path10:before {
  content: "\e9c0";
  margin-left: -5.423828125em;
  color: rgb(10, 58, 112);
}
.icon-full-Logo .path11:before {
  content: "\e9c1";
  margin-left: -5.423828125em;
  color: rgb(10, 58, 112);
}
.icon-full-Logo .path12:before {
  content: "\e9c2";
  margin-left: -5.423828125em;
  color: rgb(10, 58, 112);
}
.icon-google1 .path1:before {
  content: "\e9c3";
  color: rgb(66, 133, 244);
}
.icon-google1 .path2:before {
  content: "\e9c4";
  margin-left: -0.9521484375em;
  color: rgb(52, 168, 83);
}
.icon-google1 .path3:before {
  content: "\e9c5";
  margin-left: -0.9521484375em;
  color: rgb(251, 188, 4);
}
.icon-google1 .path4:before {
  content: "\e9c6";
  margin-left: -0.9521484375em;
  color: rgb(234, 67, 53);
}
.icon-header-logo .path1:before {
  content: "\e9c7";
  color: rgb(255, 255, 255);
}
.icon-header-logo .path2:before {
  content: "\e9c8";
  margin-left: -0.9697265625em;
  color: rgb(255, 89, 0);
}
.icon-header-logo .path3:before {
  content: "\e9c9";
  margin-left: -0.9697265625em;
  color: rgb(252, 143, 0);
}
.icon-header-logo .path4:before {
  content: "\e9ca";
  margin-left: -0.9697265625em;
  color: rgb(0, 61, 128);
}
.icon-header-logo .path5:before {
  content: "\e9cb";
  margin-left: -0.9697265625em;
  color: rgb(44, 102, 170);
}
.icon-header-logo .path6:before {
  content: "\e9cc";
  margin-left: -0.9697265625em;
  color: rgb(255, 209, 0);
}
.icon-header-logo .path7:before {
  content: "\e9cd";
  margin-left: -0.9697265625em;
  color: rgb(252, 143, 0);
}
.icon-hero0:before {
  content: "\e9ce";
}
.icon-hero1:before {
  content: "\e9cf";
}
.icon-hero21:before {
  content: "\e9d0";
}
.icon-hero3:before {
  content: "\e9d1";
}
.icon-hero41:before {
  content: "\e9d2";
}
.icon-hero6:before {
  content: "\e9d3";
}
.icon-home:before {
  content: "\e9d4";
}
.icon-info1:before {
  content: "\e9d5";
  color: #9ca3af;
}
.icon-input-go1 .path1:before {
  content: "\e9d6";
  color: rgb(223, 237, 255);
}
.icon-input-go1 .path2:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(10, 131, 237);
}
.icon-light-bulb1:before {
  content: "\e9d8";
}
.icon-linkedin-11:before {
  content: "\e9d9";
  color: #9ca3af;
}
.icon-LinkedIn1:before {
  content: "\e9da";
  color: #0a66c2;
}
.icon-Logo1 .path1:before {
  content: "\e9db";
  color: rgb(255, 255, 255);
}
.icon-Logo1 .path2:before {
  content: "\e9dc";
  margin-left: -4.5em;
  color: rgb(255, 89, 0);
}
.icon-Logo1 .path3:before {
  content: "\e9dd";
  margin-left: -4.5em;
  color: rgb(252, 143, 0);
}
.icon-Logo1 .path4:before {
  content: "\e9de";
  margin-left: -4.5em;
  color: rgb(0, 61, 128);
}
.icon-Logo1 .path5:before {
  content: "\e9df";
  margin-left: -4.5em;
  color: rgb(44, 102, 170);
}
.icon-Logo1 .path6:before {
  content: "\e9e0";
  margin-left: -4.5em;
  color: rgb(255, 209, 0);
}
.icon-Logo1 .path7:before {
  content: "\e9e1";
  margin-left: -4.5em;
  color: rgb(252, 143, 0);
}
.icon-Logo1 .path8:before {
  content: "\e9e2";
  margin-left: -4.5em;
  color: rgb(0, 61, 128);
}
.icon-map-pin1:before {
  content: "\e9e3";
  color: #6b7280;
}
.icon-message-caption1:before {
  content: "\e9e4";
}
.icon-Microsoft-logo1 .path1:before {
  content: "\e9e5";
  color: rgb(243, 243, 243);
}
.icon-Microsoft-logo1 .path2:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(243, 83, 37);
}
.icon-Microsoft-logo1 .path3:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(129, 188, 6);
}
.icon-Microsoft-logo1 .path4:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(5, 166, 240);
}
.icon-Microsoft-logo1 .path5:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(255, 186, 8);
}
.icon-nav-arrow1:before {
  content: "\e9ea";
  color: #d1d5db;
}
.icon-not-found .path1:before {
  content: "\e9eb";
  color: rgb(255, 255, 255);
}
.icon-not-found .path2:before {
  content: "\e9ec";
  margin-left: -1.5478515625em;
  color: rgb(181, 223, 234);
}
.icon-not-found .path3:before {
  content: "\e9ed";
  margin-left: -1.5478515625em;
  color: rgb(144, 206, 221);
  opacity: 0.55;
}
.icon-not-found .path4:before {
  content: "\e9ee";
  margin-left: -1.5478515625em;
  color: rgb(215, 240, 249);
}
.icon-not-found .path5:before {
  content: "\e9ef";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path6:before {
  content: "\e9f0";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path7:before {
  content: "\e9f1";
  margin-left: -1.5478515625em;
  color: rgb(255, 255, 255);
}
.icon-not-found .path8:before {
  content: "\e9f2";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path9:before {
  content: "\e9f3";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path10:before {
  content: "\e9f4";
  margin-left: -1.5478515625em;
  color: rgb(0, 102, 142);
}
.icon-not-found .path11:before {
  content: "\e9f5";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path12:before {
  content: "\e9f6";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path13:before {
  content: "\e9f7";
  margin-left: -1.5478515625em;
  color: rgb(0, 102, 142);
}
.icon-not-found .path14:before {
  content: "\e9f8";
  margin-left: -1.5478515625em;
  color: rgb(5, 85, 109);
}
.icon-not-found .path15:before {
  content: "\e9f9";
  margin-left: -1.5478515625em;
  color: rgb(249, 141, 61);
}
.icon-not-found .path16:before {
  content: "\e9fa";
  margin-left: -1.5478515625em;
  color: rgb(237, 112, 27);
}
.icon-not-found .path17:before {
  content: "\e9fb";
  margin-left: -1.5478515625em;
  color: rgb(249, 141, 61);
}
.icon-not-found .path18:before {
  content: "\e9fc";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path19:before {
  content: "\e9fd";
  margin-left: -1.5478515625em;
  color: rgb(239, 170, 58);
}
.icon-not-found .path20:before {
  content: "\e9fe";
  margin-left: -1.5478515625em;
  color: rgb(249, 141, 43);
  opacity: 0.29;
}
.icon-not-found .path21:before {
  content: "\e9ff";
  margin-left: -1.5478515625em;
  color: rgb(0, 102, 142);
}
.icon-not-found .path22:before {
  content: "\ea00";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path23:before {
  content: "\ea01";
  margin-left: -1.5478515625em;
  color: rgb(255, 255, 255);
}
.icon-not-found .path24:before {
  content: "\ea02";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path25:before {
  content: "\ea03";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path26:before {
  content: "\ea04";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path27:before {
  content: "\ea05";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path28:before {
  content: "\ea06";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path29:before {
  content: "\ea07";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path30:before {
  content: "\ea08";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path31:before {
  content: "\ea09";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path32:before {
  content: "\ea0a";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path33:before {
  content: "\ea0b";
  margin-left: -1.5478515625em;
  color: rgb(255, 159, 80);
}
.icon-not-found .path34:before {
  content: "\ea0c";
  margin-left: -1.5478515625em;
  color: rgb(255, 159, 80);
}
.icon-not-found .path35:before {
  content: "\ea0d";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path36:before {
  content: "\ea0e";
  margin-left: -1.5478515625em;
  color: rgb(249, 141, 43);
  opacity: 0.29;
}
.icon-not-found .path37:before {
  content: "\ea0f";
  margin-left: -1.5478515625em;
  color: rgb(247, 126, 45);
}
.icon-not-found .path38:before {
  content: "\ea10";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path39:before {
  content: "\ea11";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path40:before {
  content: "\ea12";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path41:before {
  content: "\ea13";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path42:before {
  content: "\ea14";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path43:before {
  content: "\ea15";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path44:before {
  content: "\ea16";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path45:before {
  content: "\ea17";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path46:before {
  content: "\ea18";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path47:before {
  content: "\ea19";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path48:before {
  content: "\ea1a";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path49:before {
  content: "\ea1b";
  margin-left: -1.5478515625em;
  color: rgb(0, 102, 142);
}
.icon-not-found .path50:before {
  content: "\ea1c";
  margin-left: -1.5478515625em;
  color: rgb(0, 0, 0);
  opacity: 0.55;
}
.icon-not-found .path51:before {
  content: "\ea1d";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path52:before {
  content: "\ea1e";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path53:before {
  content: "\ea1f";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path54:before {
  content: "\ea20";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path55:before {
  content: "\ea21";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path56:before {
  content: "\ea22";
  margin-left: -1.5478515625em;
  color: rgb(249, 141, 43);
  opacity: 0.29;
}
.icon-not-found .path57:before {
  content: "\ea23";
  margin-left: -1.5478515625em;
  color: rgb(121, 201, 232);
}
.icon-not-found .path58:before {
  content: "\ea24";
  margin-left: -1.5478515625em;
  color: rgb(73, 180, 214);
}
.icon-not-found .path59:before {
  content: "\ea25";
  margin-left: -1.5478515625em;
  color: rgb(249, 141, 43);
  opacity: 0.29;
}
.icon-not-found .path60:before {
  content: "\ea26";
  margin-left: -1.5478515625em;
  color: rgb(5, 130, 193);
}
.icon-not-found .path61:before {
  content: "\ea27";
  margin-left: -1.5478515625em;
  color: rgb(0, 102, 142);
}
.icon-not-found .path62:before {
  content: "\ea28";
  margin-left: -1.5478515625em;
  color: rgb(215, 240, 249);
}
.icon-not-found .path63:before {
  content: "\ea29";
  margin-left: -1.5478515625em;
  color: rgb(255, 191, 77);
}
.icon-not-found .path64:before {
  content: "\ea2a";
  margin-left: -1.5478515625em;
  color: rgb(255, 159, 80);
}
.icon-plus1:before {
  content: "\ea2b";
}
.icon-question-mark1:before {
  content: "\ea2c";
  color: #6b7280;
}
.icon-regenerate-text1:before {
  content: "\ea2d";
  color: #0a83ed;
}
.icon-rewrite1:before {
  content: "\ea2e";
  color: #0a83ed;
}
.icon-ring:before {
  content: "\ea2f";
}
.icon-search:before {
  content: "\ea30";
}
.icon-Signup1:before {
  content: "\ea31";
}
.icon-upload1:before {
  content: "\ea32";
  color: #6b7280;
}
.icon-Vector1:before {
  content: "\ea33";
}
.icon-x-circle-black:before {
  content: "\ea34";
}
.icon-x-circle1:before {
  content: "\ea35";
  color: #6b7280;
}
.icon-x:before {
  content: "\ea36";
}
.icon-Logo2 .path1:before {
  content: "\e95a";
  color: rgb(255, 255, 255);
}
.icon-Logo2 .path2:before {
  content: "\ea37";
  margin-left: -4.5em;
  color: rgb(255, 89, 0);
}
.icon-Logo2 .path3:before {
  content: "\ea38";
  margin-left: -4.5em;
  color: rgb(252, 143, 0);
}
.icon-Logo2 .path4:before {
  content: "\ea39";
  margin-left: -4.5em;
  color: rgb(0, 61, 128);
}
.icon-Logo2 .path5:before {
  content: "\ea3a";
  margin-left: -4.5em;
  color: rgb(44, 102, 170);
}
.icon-Logo2 .path6:before {
  content: "\ea3b";
  margin-left: -4.5em;
  color: rgb(255, 209, 0);
}
.icon-Logo2 .path7:before {
  content: "\ea3c";
  margin-left: -4.5em;
  color: rgb(252, 143, 0);
}
.icon-Logo2 .path8:before {
  content: "\ea3d";
  margin-left: -4.5em;
  color: rgb(0, 61, 128);
}
.icon-map-pin2:before {
  content: "\e95b";
  color: #6b7280;
}
.icon-message-caption2:before {
  content: "\e95c";
}
.icon-microsoft-logo .path1:before {
  content: "\e95d";
  color: rgb(243, 243, 243);
}
.icon-microsoft-logo .path2:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(243, 83, 37);
}
.icon-microsoft-logo .path3:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(129, 188, 6);
}
.icon-microsoft-logo .path4:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(5, 166, 240);
}
.icon-microsoft-logo .path5:before {
  content: "\ea41";
  margin-left: -1em;
  color: rgb(255, 186, 8);
}
.icon-nav-arrow2:before {
  content: "\e95e";
  color: #d1d5db;
}
.icon-plus2:before {
  content: "\e95f";
}
.icon-question-mark2:before {
  content: "\e960";
  color: #6b7280;
}
.icon-bars1:before {
  content: "\e961";
  color: #0a83ed;
}
.icon-rewrite2:before {
  content: "\e962";
  color: #0a83ed;
}
.icon-Signup2:before {
  content: "\e963";
}
.icon-upload2:before {
  content: "\e964";
  color: #6b7280;
}
.icon-happy-emoji:before {
  content: "\e965";
}
.icon-close-icon:before {
  content: "\e966";
  color: #6b7280;
}
