﻿@use "osavus-font" as *;

@font-face {
  font-family: $os-font-primary;
  font-weight: $os-font-weight-light;
  src:
    url("/assets/fonts/mori/PPMori-Book.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-Book.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-Book.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-Book.woff") format("woff");
}

@font-face {
  font-family: $os-font-primary;
  src:
    url("/assets/fonts/mori/PPMori-Regular.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-Regular.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-Regular.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-Regular.woff") format("woff");
}

@font-face {
  font-family: $os-font-primary;
  font-weight: $os-font-weight-semibold;
  src:
    url("/assets/fonts/mori/PPMori-Regular.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-Regular.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-Regular.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-Regular.woff") format("woff");
}

@font-face {
  font-family: $os-font-primary;
  font-weight: $os-font-weight-xbold;
  src:
    url("/assets/fonts/mori/PPMori-ExtraBold.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-ExtraBold.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-ExtraBold.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-ExtraBold.woff") format("woff");
}
