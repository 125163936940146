@use '@angular/material' as mat;
@use './theme/osavus-m3-theme';
@use './theme/osavus-color';
@use './theme/osavus-font';
@use './theme/osavus-font-face';
@use './theme/osavus-border';
@use './theme/osavus-spacing';
@use './theme/osavus-text';


@include mat.elevation-classes();
@include mat.app-background();

html {
  // Apply the light theme by default
  @include mat.all-component-themes(osavus-m3-theme.$light-theme);
}

// Tmp, to be moved out of here

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }
  .mat-row,
  .mat-header-row {
    display: table-row;
  }
}
