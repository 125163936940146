/* You can add global styles to this file, and also import other style files */

@use "assets/icons/style";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: mori;
  font-weight: 300;
  src:
    url("/assets/fonts/mori/PPMori-Book.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-Book.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-Book.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-Book.woff") format("woff");
}

@font-face {
  font-family: mori;
  src:
    url("/assets/fonts/mori/PPMori-Regular.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-Regular.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-Regular.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-Regular.woff") format("woff");
}

@font-face {
  font-family: mori;
  font-weight: 600;
  src:
    url("/assets/fonts/mori/PPMori-Regular.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-Regular.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-Regular.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-Regular.woff") format("woff");
}

@font-face {
  font-family: mori;
  font-weight: 800;
  src:
    url("/assets/fonts/mori/PPMori-ExtraBold.otf") format("opentype"),
    url("/assets/fonts/mori/PPMori-ExtraBold.ttf") format("truetype"),
    url("/assets/fonts/mori/PPMori-ExtraBold.woff2") format("woff2"),
    url("/assets/fonts/mori/PPMori-ExtraBold.woff") format("woff");
}

html {
  --mat-table-header-container-height: 54px;
  --mdc-checkbox-selected-icon-color: #0054ad;
  --mdc-checkbox-selected-focus-icon-color: #0054ad;

  --mdc-checkbox-selected-focus-state-layer-color: none;

  --mdc-checkbox-selected-hover-icon-color: #0054ad;
  --mdc-checkbox-selected-hover-icon-color: #0054ad;

  --mdc-circular-progress-active-indicator-color: #0054ad;
}

body,
div,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
textarea,
a,
button,
label,
::placeholder {
  font-family: "mori", sans-serif !important;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  margin: revert-layer;
  font-size: revert-layer;
  font-weight: revert-layer;
}

input,
select,
ng-select,
ng-select-container {
  height: 42px;
  color: #6b7280;
  font-size: small;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  padding: 4px 8px 0;
  font-size: small;
}

.ng-select.ng-select-multiple .ng-select-container {
  background-color: #f9fafb;
}

.ng-select.ng-select-multiple .ng-select-container .ng-clear-wrapper {
  z-index: 99;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container,
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input
  input {
  padding: 0 8px;
  font-size: small;
  color: #6b7280;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  margin: 0 4px;
}

.ng-dropdown-panel {
  @apply flex flex-col w-full gap-2 p-2 text-xs font-medium shadow-sm ring-gray-300 bg-gray-50;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  @apply bg-gray-50 text-gray-500;
}

.tooltip {
  @apply z-50 absolute top-6 right-0 flex-col w-full hidden gap-2 p-2 text-xs font-medium rounded-md shadow-sm ring-gray-300 bg-gray-50 ring-1 ring-inset;
}

.has-tooltip:hover .tooltip,
.has-tooltip:focus .tooltip {
  @apply flex;
}

.centered {
  @apply flex items-center justify-center;
}

.overflow-cell.mdc-data-table__cell {
  @apply overflow-visible;
}

/* TODO: extract this to separate overrides file */
.mat-mdc-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th,
td {
  vertical-align: middle;
}

.mat-mdc-checkbox-ripple {
  display: none;
}

.mat-column-applied .mat-sort-header-container {
  justify-content: end;
}

.mat-column-headcount .mat-sort-header-container {
  justify-content: center;
}

.mat-column-actions.cdk-column-actions {
  text-align: center;
}

/* Cookie style overrides begin */

.cc-window {
  @apply bg-gray-50 rounded;
  padding: 1.5rem !important;
  max-width: 40rem !important;
}

.cc-btn.cc-allow {
  @apply text-white bg-osavus-blue-50 rounded-lg;
  @apply cursor-pointer text-sm leading-3 border-2 border-osavus-blue-50;
  @apply hover:bg-osavus-blue-60 hover:border-osavus-blue-60 xl:leading-3;
  @apply 2xl:leading-6 gap-1 px-2.5 py-2.5 m-0 w-28 ;
}

a.cc-btn.cc-deny {
  @apply text-osavus-blue-50 border-gray-200 border-2 rounded-lg w-28 no-underline hover:bg-gray-200;
}

.cc-compliance.cc-highlight {
  @apply justify-end flex gap-4;
}

.cc-message {
  @apply text-sm text-gray-500;
}

.cc-link {
  @apply text-gray-900 font-semibold;
}

.cc-floating .cc-compliance > .cc-btn {
  flex: 0 !important;
}
.cc-revoke {
  @apply rounded bg-gray-500 text-white w-40 flex justify-center;
}

/* Cookie style overrides end */

.cookie-policy-backdrop {
  @apply block h-full w-full bg-gray-600 fixed top-0 left-0 opacity-90  pointer-events-none z-50;
}

/* accordion overrides  */
.mat-expansion-panel-content {
  @apply py-3;
  @apply px-4;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.upload-button {
  display: flex;
}

.upload-button button.leading-6 {
  line-height: 2.625rem !important;
}

.mat-tooltip-success .mdc-tooltip__surface {
  background-color: #d9f99d;
  font-size: x-small;
  color: #030B14;
  font-weight: 600;
}

.mat-mdc-icon-button span.mat-mdc-button-touch-target {
  height: unset;
}

.actions-icon-button {
  height: 1.5rem !important;
  width: 1.5rem !important;
  padding: 0 !important;

  .mat-icon {
    transform: scale(0.65);
  }
}


.custom-width {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal; 
  word-break: break-word;
  min-width: 6.25rem; 
  max-width: 13rem;
  max-width: 100%; 
  width: auto;
  flex-grow: 1;
}

[type='password'] {
  border-width: 0px;
}