﻿// Font
$os-font-primary: "PP Mori";
$os-font-mono: "IBM Plex Mono";

// Font -> Size
$os-font-size-100: 10px;
$os-font-size-200: 12px;
$os-font-size-300: 14px;
$os-font-size-400: 16px;
$os-font-size-500: 18px;
$os-font-size-600: 22px;
$os-font-size-700: 24px;
$os-font-size-800: 26px;
$os-font-size-900: 28px;
$os-font-size-1000: 32px;

// Font -> Weight
$os-font-weight-light: 200;
$os-font-weight-regular: 400;
$os-font-weight-medium: 500;
$os-font-weight-semibold: 600;
$os-font-weight-bold: 700;
$os-font-weight-xbold: 800;

// Font -> Line Height
$os-line-height-100: 16px;
$os-line-height-200: 20px;
$os-line-height-300: 24px;
$os-line-height-400: 26px;
$os-line-height-500: 30px;
$os-line-height-600: 34px;
$os-line-height-700: 40px;
